import { useMemo, useEffect } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Box, Grid } from "@material-ui/core";
import { debounce } from "lodash";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import cn from "classnames";
import { useFormContext } from "react-hook-form";
import { addDays } from "date-fns";
import { useCampaignData } from "pages/adm/builder/context/CampaignDataContext";
import { InputField } from "components/form/InputField";
import { DateField } from "components/form/DateField";
import { SelectField } from "components/form/SelectField";
import { RadioCardField } from "components/form/RadioCardField";

import { updateCampaignExtraData, updateCampaign } from "../../../../../graphQL";
import "./CampaignInfoCard.css";
import { ToggleText } from "../ToggleText";
import { TitleText } from "../TitleText";
import { FORMAT_OPTIONS, MINIMUM_BUSINESS_DAYS, MAXIMUM_CAMPAIGN_DAYS, RECIPIENT_MOVED_OPTIONS } from "../../constants";
import { CampaignInfoFormData } from "../../schemas/campaignInformation";
import checkCircleIcon from "../../../../../static/media/check-circle.svg";
import { getNextDayByBusinessDays, isAheadByBusinessDays } from "../../utils";
import { AlertInvalidDates } from "../AlertInvalidDates";

interface CampaignInfoCardProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  triggerAnalyticsEvent: (eventName: string, eventProperties?: Record<string, any>) => void;
  checkingCampaignName: boolean;
  isReadOnly?: boolean;
}

export const CampaignInfoCard = ({
  isOpen,
  setIsOpen,
  triggerAnalyticsEvent,
  checkingCampaignName,
  isReadOnly,
}: CampaignInfoCardProps) => {
  const { campaignId } = useParams();
  const { refetchCampaign, campaignData } = useCampaignData();
  const {
    register,
    formState: { errors, isValid },
    setValue,
    watch,
    trigger,
  } = useFormContext<CampaignInfoFormData>();

  const isDayValid = useMemo(() => {
    return isAheadByBusinessDays(watch("campaignStartDate"), MINIMUM_BUSINESS_DAYS);
  }, [watch("campaignStartDate")]);

  const saveFormatOnDB = debounce(async (newFormat: string, newStartDate: string) => {
    const payloadCampaignExtraData: { campaignId: any; flyerType?: string; startDate?: string } = {
      campaignId,
    };
    if (newFormat && newFormat !== campaignData?.flyerType) {
      payloadCampaignExtraData.flyerType = newFormat;
    }
    let parsedDate;
    if (isDayValid && String(new Date(newStartDate).getTime()) !== campaignData?.startDate) {
      parsedDate = `${moment(newStartDate).format("YYYY-MM-DD")}T00:00:00.000Z`;
      payloadCampaignExtraData.startDate = parsedDate;
    }
    if (parsedDate) {
      updateCampaign({ campaignId, startDate: parsedDate });
    }
    if (Object.values(payloadCampaignExtraData).length > 1) {
      await updateCampaignExtraData(payloadCampaignExtraData);
      refetchCampaign();
    }
  }, 600);

  useEffect(() => {
    saveFormatOnDB(watch("format"), watch("campaignStartDate"));
    return () => {
      saveFormatOnDB.cancel();
    };
  }, [watch("format"), watch("campaignStartDate"), isDayValid]);

  return (
    <Grid className={`infoCardContainer ${!isOpen ? "closed" : ""}`} xs={12}>
      <Box className="infoCardHeaderContainer">
        <div className="titleContainer">
          <TitleText>Campaign Info</TitleText>
          {isValid && <img src={checkCircleIcon} alt="" className="checkIconInfoCard" />}
        </div>

        <div className="seeMoreToggle" onClick={() => setIsOpen(!isOpen)}>
          <ToggleText>See {isOpen ? "less" : "more"}</ToggleText>
          <KeyboardArrowDownIcon fontSize="small" className={cn("toggleIcon", isOpen && "toggleIconOpen")} />
        </div>
      </Box>
      <form>
        <InputField
          label="Campaign name"
          placeholder="Campaign name"
          error={errors.campaignName?.message}
          {...register("campaignName")}
          value={watch("campaignName")}
          onChange={(e) => {
            setValue("campaignName", e.target.value);
            trigger("campaignName");
          }}
          onBlur={() => {
            trigger("campaignName");
          }}
          required
          loading={checkingCampaignName}
          disabled={isReadOnly}
        />
        <DateField
          label="Launch date"
          description="The date your campaign will be sent via USPS to begin delivery"
          name="campaignStartDate"
          error={errors.campaignStartDate?.message}
          onChange={(date) => {
            date && setValue("campaignStartDate", date);
            trigger("campaignStartDate");
          }}
          value={watch("campaignStartDate")}
          minDate={getNextDayByBusinessDays(new Date(), MINIMUM_BUSINESS_DAYS)}
          maxDate={addDays(new Date(), MAXIMUM_CAMPAIGN_DAYS)}
          required
          isUS
          disabled={isReadOnly}
        />
        {!isDayValid && !isReadOnly && (
          <div className="infoCardAlertInvalidDatesContainer">
            <AlertInvalidDates dateLimit={MINIMUM_BUSINESS_DAYS} />
          </div>
        )}
        <SelectField
          label="When recipient moved"
          description="Select the action to take when the recipient moves"
          name="updateAddressOnMove"
          error={errors.updateAddressOnMove?.message}
          value={watch("updateAddressOnMove")}
          onChange={(value) => {
            setValue("updateAddressOnMove", value as string);
            trigger("updateAddressOnMove");
            triggerAnalyticsEvent("ADM NCOA address choice", {
              ncoaChoice: value === "yes" ? "Send to new address" : "Don't send to new address",
            });
          }}
          options={RECIPIENT_MOVED_OPTIONS}
          required
          disabled={isReadOnly}
        />
        <RadioCardField
          label="Format"
          description="It influences your campaign total cost"
          name="format"
          error={errors.format?.message}
          value={watch("format")}
          onChange={(value) => {
            setValue("format", value);
            trigger("format");
          }}
          options={FORMAT_OPTIONS}
          required
          disabled={isReadOnly}
        />
      </form>
    </Grid>
  );
};
