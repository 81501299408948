import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { updateCampaign } from "graphQL/mutations/campaign";
import { updateCampaignExtraData } from "graphQL/mutations/campaignExtraData";
import { campaignDetailsSchema, CampaignDetailsFormData } from "../../schemas/campaignDetails";
import { useCampaignData } from "../../context/CampaignDataContext";

export const useCampaignDetails = () => {
  const { campaignData } = useCampaignData();
  const methods = useForm<CampaignDetailsFormData>({
    resolver: zodResolver(campaignDetailsSchema),
    mode: "onBlur",
    defaultValues: {
      hasQRCode: "no",
      hasRedemptionCoupon: "no",
      qrCodeUrl: "",
      couponCode: "",
      additionalComment: "",
    },
  });

  useEffect(() => {
    if (campaignData?.id) {
      methods.setValue("additionalComment", campaignData.campaignComments ?? "");
      methods.setValue("qrCodeUrl", campaignData.qrCodeLink ?? "");
      methods.setValue("couponCode", campaignData.staticCouponCode ?? "");
      if (campaignData.qrCodeLink) {
        methods.setValue("hasQRCode", "yes");
      }
      if (campaignData.staticCouponCode) {
        methods.setValue("hasRedemptionCoupon", "yes");
      }
    }
  }, [campaignData?.id]);

  const onSubmitCampaignDetails = async (data: CampaignDetailsFormData) => {
    try {
      if (!campaignData?.id) {
        throw new Error("Campaign ID is required");
      }
      await updateCampaign({
        campaignId: campaignData.id,
        comments: data.additionalComment || null,
      });
      await updateCampaignExtraData({
        campaignId: campaignData.id,
        qrCodeLink: data.qrCodeUrl || null,
        staticCouponCode: data.couponCode || null,
        isTrackableCoupon: data.hasRedemptionCoupon === "yes" ? 1 : 0,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return {
    methods,
    onSubmitCampaignDetails,
  };
};
