import { useCallback, useEffect, useMemo } from "react";
import { toFixed3, toFixed2 } from "utils/formators";
import { calculateSubtotalAndPrintingCosts, calculateTotalMissionsH2H } from "../../shared/campaign";
import {
  getMinimumMissionLimitError,
  getMaximumMissionLimitError,
  calculateSelectedAddressesH2H,
  isExistInvalidMissionsInH2H,
  isH2HMissionsInWeekMoreThanSettings,
  formatD2DMission,
  splitH2HMissionsFromWeeks,
  isAllLocationHasMission,
  calculateTaxAndTotalCost,
} from "./utils";
import {
  StoreType,
  CostsCalculationDataType,
  MissionD2DType,
  MissionH2HType,
  SettingsType,
  CityLimitErrorType,
} from "../../store/types";
import { useStore } from "../../store";
import { CAMPAIGN_INTERNAL_STATUSES } from "../../pages/constants";

const EMPTY_ERROR: CityLimitErrorType = { title: "", message: "" };

const useCalculating = (): {
  recalculateCampaignCost: () => void;
} => {
  const {
    city,
    campaign: { flyerType, flyerWeight, isD2D, campaignDuration, internalStatus },
    country,
    costsCalculationData,
    missions,
    timezone,
    updateCostsCalculationData,
    updateErrors,
    setMissions,
    updateCampaign,
  }: StoreType = useStore();

  if (!city) {
    return {
      recalculateCampaignCost: () => {},
    }; // Return empty object if city is not available
  }

  const isUS = country.id == "14";
  const citySalesTaxRate = country.citiesList.find((selectedCity) => selectedCity.id == city.id)?.salesTaxRate;
  const { printingCosts, settings, detailedCost }: CostsCalculationDataType = costsCalculationData;
  const { flyerPack, distributionCosts, travelCosts, permitCosts, outfitCosts, countryTaxRate }: SettingsType =
    settings;

  const { amountOff, percentOff } = detailedCost;
  const missionsCount: number = detailedCost.distribution.quantity;
  const missionCostWithoutPrinting: number = detailedCost.missionCostWithoutPrinting;

  let totalMissionsH2H: MissionH2HType[] = [];
  let totalMissionsD2D: MissionD2DType[] = [];
  let h2hMissionsPerWeek: Array<Array<MissionH2HType>> = [];
  let calculatedCampaignDuration = 0;
  let outfit = { quantity: 0, rate: 0, total: 0 };

  const weeklyMissionLimit: number = settings.weeklyMissionLimit;

  const cityAddresses: MissionH2HType[] = useMemo(() => {
    if (isD2D) {
      return [];
    } else {
      return calculateSelectedAddressesH2H(city);
    }
  }, [city]);

  const { baPerLocation, defaultCampaignLimits } = country.countryDefaultSettings;

  const newTotalMissionsH2H: MissionH2HType[] = useMemo(() => {
    if (isD2D) {
      return [];
    } else {
      return calculateTotalMissionsH2H(cityAddresses, baPerLocation);
    }
  }, [cityAddresses, baPerLocation]);

  if (isD2D) {
    totalMissionsD2D = city.addresses.reduce((acc: any[], address: any) => {
      if (address.id) {
        const formattedD2DMission: MissionD2DType = formatD2DMission(address);
        acc.push(formattedD2DMission);
      }
      return acc;
    }, []);
    calculatedCampaignDuration = weeklyMissionLimit ? Math.ceil(totalMissionsD2D.length / weeklyMissionLimit) : 1;
  } else {
    h2hMissionsPerWeek = splitH2HMissionsFromWeeks(newTotalMissionsH2H);
    calculatedCampaignDuration = h2hMissionsPerWeek.length;
    totalMissionsH2H = newTotalMissionsH2H;

    if (newTotalMissionsH2H.length > 10) {
      const maxMissionsByWeek = h2hMissionsPerWeek.reduce((max, currentArray) => {
        return Math.max(max, currentArray.length);
      }, 0);

      const outfitQuantity = Math.round(maxMissionsByWeek / 2);

      outfit = { quantity: outfitQuantity, rate: outfitCosts, total: outfitQuantity * outfitCosts };
    }
  }

  useEffect(() => {
    //SET NEW MISSIONS
    if (!internalStatus || internalStatus === CAMPAIGN_INTERNAL_STATUSES.DRAFT) {
      if (isD2D) {
        if (totalMissionsD2D.length && missions.length !== missionsCount) {
          setMissions(totalMissionsD2D);
        }
      } else {
        if (totalMissionsH2H.length && missions.length !== missionsCount) {
          setMissions(totalMissionsH2H);
        }
      }

      if (campaignDuration !== calculatedCampaignDuration) {
        updateCampaign({ campaignDuration: calculatedCampaignDuration });
      }
    }
  });

  const getErrors = useCallback(
    (missionCount: number) => {
      const campaignMissionLimitD2D: number = weeklyMissionLimit ? weeklyMissionLimit * 12 : Infinity;
      if (missionCount < settings.minimumMissionLimit) {
        const cityMissionsLimitError: CityLimitErrorType = getMinimumMissionLimitError({
          isD2D,
          minimumMissionLimit: settings.minimumMissionLimit,
          cityName: city.name,
        });
        updateErrors({ cityMissionsLimitError });
      } else if (isD2D && missionCount > campaignMissionLimitD2D) {
        const cityMissionsLimitError: CityLimitErrorType = getMaximumMissionLimitError({
          isD2D,
          campaignMissionLimit: campaignMissionLimitD2D,
        });
        updateErrors({ cityMissionsLimitError });
      } else if (
        !isD2D &&
        weeklyMissionLimit &&
        isH2HMissionsInWeekMoreThanSettings({
          missionsPerWeek: h2hMissionsPerWeek,
          weeklyMissionLimit,
        })
      ) {
        const cityMissionsLimitError: CityLimitErrorType = getMaximumMissionLimitError({
          isD2D,
          campaignMissionLimit: weeklyMissionLimit,
        });
        updateErrors({ cityMissionsLimitError });
      } else {
        updateErrors({ cityMissionsLimitError: EMPTY_ERROR });
      }

      if (!isD2D && !isAllLocationHasMission(city.addresses)) {
        updateErrors({ haveLocationWithoutMission: true });
      } else {
        updateErrors({ haveLocationWithoutMission: false });
      }

      if (!isD2D) {
        const hasInvalidMission: boolean = isExistInvalidMissionsInH2H(
          totalMissionsH2H,
          timezone,
          defaultCampaignLimits
        );
        if (hasInvalidMission) {
          updateErrors({ existInvalidMissionsInH2H: true });
        } else {
          updateErrors({ existInvalidMissionsInH2H: false });
        }
      }
    },
    [totalMissionsH2H, totalMissionsD2D, city, timezone]
  );

  const recalculateCampaignCost = useCallback(() => {
    const totalMissionsCount: number = totalMissionsH2H.length || totalMissionsD2D.length;
    if (internalStatus === CAMPAIGN_INTERNAL_STATUSES.DRAFT) {
      getErrors(totalMissionsCount);
    }
    // if (missionsCount === totalMissionsCount || internalStatus !== CAMPAIGN_INTERNAL_STATUSES.DRAFT) {
    if (internalStatus !== CAMPAIGN_INTERNAL_STATUSES.DRAFT) {
      console.log("not calculating");
      return; // Early return if missions count hasn't changed
    }
    console.log("calculating");

    const { subtotal, printingCost }: { subtotal: number; printingCost: number } = calculateSubtotalAndPrintingCosts({
      missionCostWithoutPrinting,
      missionsCount: totalMissionsCount,
      printingCosts,
      flyerType,
      flyerWeight,
      flyerPack,
      outfitTotal: outfit.total,
    });

    const fixed2Subtotal = toFixed2(subtotal);
    const fixed3PrintingCost = toFixed3(printingCost);

    const totalFlyers: number = flyerPack * totalMissionsCount;
    const costPerFlyer: number = !isNaN(fixed2Subtotal / totalFlyers) ? fixed2Subtotal / totalFlyers : 0;

    const recalculatedCosts = {
      distribution: {
        quantity: totalMissionsCount,
        rate: distributionCosts,
        total: toFixed2(distributionCosts * totalMissionsCount),
      },
      travel: {
        quantity: totalMissionsCount,
        rate: travelCosts,
        total: toFixed2(travelCosts * totalMissionsCount),
      },
      permit: {
        quantity: totalMissionsCount,
        rate: permitCosts,
        total: toFixed2(permitCosts * totalMissionsCount),
      },
      printing: {
        quantity: totalFlyers,
        rate: printingCost,
        total: toFixed3(totalFlyers * fixed3PrintingCost),
      },
      outfit,
    };
    const { tax, totalCost } = calculateTaxAndTotalCost({ countryTaxRate, subtotal: fixed2Subtotal, amountOff });

    updateCostsCalculationData({
      ...costsCalculationData,
      costPerFlyer,
      detailedCost: {
        ...recalculatedCosts,
        subtotal: fixed2Subtotal,
        tax: toFixed2(tax),
        totalCost: toFixed2(totalCost),
        missionCostWithoutPrinting,
        amountOff,
        percentOff,
      },
    });
    setMissions(isD2D ? totalMissionsD2D : totalMissionsH2H);
  }, [
    missionsCount,
    totalMissionsH2H.length,
    totalMissionsD2D.length,
    city,
    amountOff,
    percentOff,
    flyerType,
    flyerWeight,
  ]);

  return { recalculateCampaignCost };
};

export default useCalculating;
