import { useMemo } from "react";
import { getDMPrintingCostByFlyer } from "shared/campaign";
import { roundTo2 } from "utils/formators";
import { calculateTaxAndTotalCost } from "Hooks/useEDDMCostsCalculationV2/utils";
import { useQuery } from "react-query";

import { useClientConfig } from "../../context/ClientConfigContext";
import { useCampaignData } from "../../context/CampaignDataContext";
import { ADM_FORMAT } from "../../constants";

const DEFAULT_CAMPAIGN_COST_PREVIEW = {
  lines: [],
  subtotal: 0,
  tax: 0,
  total: 0,
  amounfOff: 0,
};

export const useCampaignCostPreviewCalculation = ({
  format,
}: {
  format?: ADM_FORMAT;
} = {}) => {
  const { campaignData, activeAudienceDetails } = useCampaignData();
  const { admPrintingCosts, country } = useClientConfig();

  const flyersAmount = useMemo(
    () => activeAudienceDetails.reduce((acc, audience) => acc + audience.metadata.rowsAmount, 0),
    [activeAudienceDetails]
  );

  const amountOff = useMemo(() => {
    if (!campaignData) return 0;
    return campaignData.percentOff ? (campaignData.percentOff / 100) * campaignData.totalCosts : campaignData.amountOff;
  }, [campaignData?.percentOff, campaignData?.amountOff]);

  const costPerFlyer = useMemo(() => {
    if (!campaignData || !activeAudienceDetails || !admPrintingCosts || !country) return 0;
    return getDMPrintingCostByFlyer({
      printingCosts: admPrintingCosts,
      flyerType: format || campaignData.flyerType,
      flyersAmount,
    });
  }, [campaignData, activeAudienceDetails, admPrintingCosts, format, country]);

  const calculateCampaignCostPreview = () => {
    if (!campaignData || !activeAudienceDetails || !admPrintingCosts || !country) return;
    const countryTaxRate = country?.countryDefaultSettings?.countryTaxRate || 0;
    const discountDetails = {
      amountOff: campaignData.amountOff,
      percentOff: campaignData.percentOff,
      stripeCouponCode: campaignData.stripeCouponCode,
    };
    const subtotal = flyersAmount * costPerFlyer;
    const fixed2Subtotal = roundTo2(subtotal);
    const amountOff = roundTo2(
      discountDetails.percentOff ? (discountDetails.percentOff / 100) * fixed2Subtotal : discountDetails.amountOff || 0
    );

    const { tax, totalCost } = calculateTaxAndTotalCost({
      countryTaxRate,
      subtotal: fixed2Subtotal,
      amountOff: amountOff || null,
    });

    const lines = [
      {
        title: "Addressed Direct Mail",
        quantity: flyersAmount,
        price: fixed2Subtotal,
      },
    ];

    if (discountDetails.stripeCouponCode) {
      lines.push({
        title: `Coupon ${discountDetails.stripeCouponCode}`,
        quantity: 1,
        price: -amountOff,
      });
    }

    return {
      lines,
      subtotal: fixed2Subtotal,
      tax,
      total: totalCost,
    };
  };

  const { data: campaignCostPreview = DEFAULT_CAMPAIGN_COST_PREVIEW, refetch: refetchCampaignCostPreview } = useQuery({
    queryKey: [
      "campaignCostPreview",
      campaignData?.id,
      campaignData?.stripeCouponCode,
      activeAudienceDetails.map((audience) => audience.id).join(","),
      format || campaignData?.flyerType,
      country?.id,
      amountOff,
    ],
    queryFn: () => {
      return calculateCampaignCostPreview();
    },
  });

  const submittedCampaignCostPreview = useMemo(() => {
    if (!campaignData || !campaignData.isSubmitted) return null;
    const lines = [
      {
        title: "Addressed Direct Mail",
        quantity: flyersAmount,
        price: roundTo2(campaignData.subtotal),
      },
    ];
    let amountOff = 0;
    if (campaignData.stripeCouponCode && (campaignData.amountOff || campaignData.percentOff)) {
      amountOff = roundTo2(campaignData.amountOff || (campaignData.percentOff! / 100) * campaignData.totalCosts);
      lines.push({
        title: `Coupon ${campaignData.stripeCouponCode}`,
        quantity: 1,
        price: -amountOff,
      });
    }
    return {
      lines: lines,
      subtotal: roundTo2(campaignData.subtotal),
      tax: roundTo2(campaignData.taxes ?? 0),
      total: roundTo2(campaignData.totalCosts),
      amountOff: amountOff,
    };
  }, [campaignData]);

  return {
    campaignCostPreview:
      campaignData?.isSubmitted && submittedCampaignCostPreview ? submittedCampaignCostPreview : campaignCostPreview,
    refetchCampaignCostPreview,
    costPerFlyer,
    flyersAmount,
  };
};
