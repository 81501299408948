import { roundTo2 } from "utils/formators";

const calculateTaxAndTotalCost = ({
  countryTaxRate,
  subtotal,
  amountOff,
}: {
  countryTaxRate: number;
  subtotal: number;
  amountOff: number | null;
}) => {
  const subtotalWithDiscount = amountOff && amountOff < subtotal ? subtotal - amountOff : subtotal;
  const tax = (subtotalWithDiscount * countryTaxRate) / 100;

  const totalCost: number = subtotalWithDiscount + tax;

  return { tax: roundTo2(tax), totalCost: roundTo2(totalCost) };
};

export { calculateTaxAndTotalCost };
